import styled from "styled-components";

export const LinkMenu = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.secondarydark};
  background-color: ${(props) => props.theme.colors.secondary};
  width: calc(100% - 14px);
  transition: all 0.5s;
  position: relative;
  display: block;
  z-index: 40;
  background-color: transparent;
  transition: all 0.3s ease;
`;

export const Container = styled.header`
  background-color: ${(props) => props.theme.colors.secondary};
  width: 483px;
  height: 100vh;
  z-index: 90;
  padding: 55px 75px 88px 75px;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;

  .change-language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 130%;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.colors.secondarydark};
      background-color: ${(props) => props.theme.colors.secondary};
      width: auto;
      transition: all 0.5s;
      position: relative;
      display: block;
      z-index: 40;
      background-color: transparent;
      transition: all 0.3s ease;
    }
    button {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.secondarydark};
      background-color: transparent;
      text-transform: uppercase;
      font-weight: bold;
      border: 1px solid ${(props) => props.theme.colors.secondarydark};
      border-radius: 4px;
      padding: 10px 10px;
      letter-spacing: 2px;
      transition: all 0.3s ease;
    }
  }

  &.active {
    right: 0px;
    transition: all 0.5s;
  }
  .container-logo-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 93px;
    img {
      &:last-child {
        cursor: pointer;
      }
    }
  }

  ul {
    &.link {
      li {
        margin-bottom: 42px;
        position: relative;
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 130%;
        letter-spacing: 0.02em;
        color: ${(props) => props.theme.colors.secondarydark};
        background-color: ${(props) => props.theme.colors.secondary};
        width: calc(100% - 14px);
        transition: all 0.5s;
        position: relative;
        display: block;
        z-index: 40;
        background-color: transparent;
        transition: all 0.3s ease;
        a {
          text-decoration: none;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 130%;
          letter-spacing: 0.02em;
          color: ${(props) => props.theme.colors.secondarydark};
          background-color: ${(props) => props.theme.colors.secondary};
          width: calc(100% - 14px);
          transition: all 0.5s;
          position: relative;
          display: block;
          z-index: 40;
          background-color: transparent;
          transition: all 0.3s ease;
        }
        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          -webkit-mask: url("/img/icone/icone-slide-seta-dir.svg");
          background-color: ${(props) => props.theme.colors.secondarydark};
          z-index: 20;
          transition: all 0.2s;
        }
        &:hover {
          ${LinkMenu} {
            color: ${(props) => props.theme.colors.tertiary};
            transition: all 0.5s;
          }
          &:before {
            background-color: ${(props) => props.theme.colors.tertiary};
            right: 0px;
            transition: all 0.2s;
          }
        }
      }
    }
  }

  .container-contato-social {
    margin-top: auto;
    .contato-social {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 100%;
        color: ${(props) => props.theme.colors.secondarydark};
        transition: all 0.3s ease;
      }

      .social {
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: ${(props) => props.theme.colors.secondarydark};
      transition: all 0.3s ease;
    }
  }

  @media (max-width: 1366px) {
    .container-logo-close {
      margin-bottom: 25px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    padding-right: 10vw;
    padding-left: 10vw;
    overflow: scroll;
    .container-contato-social {
      .contato-social {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        margin-left: auto;
      }
    }
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
  z-index: -1;
  transition: all 0.2s;
  &.active {
    z-index: 999;
    overflow-y: scroll;
    .container-modal {
      right: 0vw;
      transition: all 0.2s;
    }
  }
  .container-modal {
    position: absolute;
    top: 0px;
    right: -100vw;
    transition: all 0.2s;
  }
`;
