import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { useSite } from "../../hooks/useSite";

import { Container, ContainerModal } from "./styles";

export default function Menu() {
  const { handleClose, modal, setModal, config } = useSite();
  const router = useRouter();
  return (
    <ContainerModal className={`${modal.menu ? "active" : ""}`}>
      <Container className="container-modal">
        <div className="container-logo-close">
          <button
            onClick={() => {
              router.push("/");
              handleClose("menu");
            }}
          >
            <Image
              src="/img/icone/icone-logo.svg"
              width={207}
              height={52}
              alt="Logo Caravela"
            />
          </button>
          <button onClick={() => handleClose("menu")}>
            <Image
              src="/img/icone/close.svg"
              height={14}
              width={14}
              alt="Fechar"
            />
          </button>
        </div>

        <nav itemScope itemType="http://schema.org/Menu">
          <ul className="link">
            <li itemProp="name" onClick={() => handleClose("menu")}>
              <Link href="/">Home</Link>
            </li>
            <li itemProp="name" onClick={() => handleClose("menu")}>
              <Link href="/servicos" replace>
                Serviços
              </Link>
            </li>
            <li itemProp="name" onClick={() => handleClose("menu")}>
              <Link href="/portifolio">Cases</Link>
            </li>
            <li itemProp="name" onClick={() => handleClose("menu")}>
              <Link href="/blog">Blog</Link>
            </li>
            <li
              onClick={() => {
                setModal({
                  ...modal,
                  menu: false,
                  orcamento: true,
                });
              }}
            >
              Orçamento
            </li>
          </ul>
        </nav>
        <div className="container-contato-social">
          <div className="contato-social">
            <div className="social">
              <Link
                href={config?.social?.instagram || ""}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  src="/img/icone/insta-footer.svg"
                  width={19}
                  height={19}
                  alt="instagram"
                />
              </Link>
              <Link
                href={config?.social?.facebook || ""}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  src="/img/icone/face-footer.svg"
                  width={11}
                  height={19}
                  alt="Facebook"
                />
              </Link>
            </div>
          </div>

          <h4>
            {config?.endereco?.logradouro || ""},{" "}
            {config?.endereco?.numero || ""} - {config?.endereco?.bairro || ""}
            <br /> {config?.endereco?.cidade || ""} {config?.endereco?.estado} -
            CEP {config?.endereco?.cep || ""}
          </h4>
        </div>
      </Container>
    </ContainerModal>
  );
}
